.OtherSocietyDetailsViewerMainDiv{
    padding:25px;
    width: 80%;
    margin-top:2%;
    margin-left:10%;
    z-index: 5;
    font-size: small;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
   
    
}

.OtherSocietyDetailsViewerMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
    height:70vh;
    overflow-y: auto;
}

.OtherSocietyDetailsViewerMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.OtherSocietyDetailsViewerMainDiv table{
    width:100%;
   border: 1px solid blue;
   border-collapse: collapse;
  
}


.OtherSocietyDetailsViewerMainDiv thead{
   background-color: blue;
   color:white;
   border-top-left-radius: 8px;
   border-top-right-radius: 8px;
}

.OtherSocietyDetailsViewerMainDiv tbody{
   border-top-left-radius: 8px;
   border-top-right-radius: 8px;
   text-align: center;
}

.OtherSocietyDetailsViewerMainDiv thead td{
   padding:15px;
   
}

.OtherSocietyDetailsViewerMainDiv tbody td{
   padding:15px;
   border: 1px solid blue;
}

.OtherSocietyDetailsViewerMainDiv img{
    width: 150px;
    height:150px;
}

