.AboutUsWriteUpMainDiv {
    width: 100%;
    float: right;
    text-align: justify;
    text-align: center;
    border-radius: 8px;
    height:fit-content;
    position: relative;
}

.AboutUsHeaderDiv{
    width: 100%;
    background-color: blue;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    padding-top: 11px;
    padding-bottom: 11px;
    
}



.AboutUsHeaderDiv h4{
    color:white;
    text-align: justify;
    font-family: 'Verdana';
    margin:20px;
    font-size: medium;
}

.AboutUsContentDivWrapper{
  width: 100%;
}

.AboutUsContentDiv{
    width: fit-content;
    background-color: white;
    padding: 20px;
    margin-top: 60px;
    height: 80vh;
    overflow-y:auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.AboutUsContentDiv p {
    font-family: 'Verdana';
    text-align: justify;
    line-height: 1.8;
    font-weight: Bold;
    text-decoration: underline;
    color:blue;
    font: size 14px;;
    padding: 5px;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
 .AboutUsContentDiv::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.AboutUsContentDiv {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}


/* width */
::-webkit-scrollbar {
    width: 15px;
    height: auto;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
    
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightblue;
    border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: blue;
  }