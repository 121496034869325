.BannerBody {

    width:100%;
    text-align: center;
    padding-top:30px;
    display: flex;
    
}


.LogoDiv{
    display: inline-block;
    margin-right:10px;
    width: fit-content;
    margin-left:15%;
}

.BannerContent{
    display: inline-block;
    width: fit-content;
    color: whitesmoke;
    font-size: 28px;
    font-family: 'Roboto';
    font-weight: bold;
    
}

.logo{
    width: 80px;
    height: 80px;
    border-radius: 50px;
}

.logoName{
    width: 150px;
    height: 20px;
    border-radius: 25px;
}