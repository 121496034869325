.FormDownLoadWidget {
    background-color: blue;
    color:white;
    padding: 5px;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: bold;
    width:fit-content;
    border: 2px solid darkblue;
    border-radius: 8px;
}

.FormDownLoadHeader{
    padding: 5px;
}

.FormDownLoadContent{
   background-color: white;
   color: blue;
   text-align: center;
   padding:40px;
}

.readMore{
    margin:4px;
}

