
.AccountsHeadCreationMainDiv {
    width: 80%;
    height: 60vh;
    padding:30px;
    margin-left: 12%;
    font-family: 'Courier New', Courier, monospace;
    color: black;
   
}

.AccountsHeadCreationMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.AccountsHeadCreationMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
  }

.AccountsHeadCreationMainDiv .FormFieldDiv select, 
.AccountsHeadCreationMainDiv .FormFieldDiv input[type=text],
.AccountsHeadCreationMainDiv .FormFieldDiv input[type=number],
.AccountsHeadCreationMainDiv .FormFieldDiv input[type=month] {
    padding: 12px;
    font-family: 'courier';
    font-weight: bold;
    font-size: small;
    width: 250px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.AccountsHeadCreationMainDiv .FormFieldDiv
select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=month]:focus {
    background-color: cornsilk;
}

.AccountsHeadCreationMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
   
    padding:30px;

}

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.AccountsHeadCreationMainDiv legend {
    text-align: justify;
    color: blue;
}