.NewUserRegsitrationForm{
    padding: 10px;
    width: fit-content;
    border-radius: 8px;
    margin-left:20%;
    
}

.NewUserRegsitrationForm fieldset{
    background-color:ghostwhite;
    border-radius: 8px;
    padding-left: 70px;
    padding-right: 70px;
    text-align: left;
    border: solid 5px blue;
    color:blue;
    font-weight: bold;
}

.NewUserRegsitrationForm fieldset legend{
    padding:10px;
    background-color: blue;
    color:ghostwhite;
    border-radius: 8px;
    border: 5px solid blue;
}

.FormFieldDiv label {
    font-family: 'courier';
    font-weight: blue;
    font-size: 12px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.NewUserRegsitrationForm .FormFieldDiv input[type=text],
.NewUserRegsitrationForm .FormFieldDiv input[type=email], 
.NewUserRegsitrationForm .FormFieldDiv input[type=password], 
.NewUserRegsitrationForm .FormFieldDiv input[type=number]
{
    width:350px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
}

.NewUserRegsitrationForm .FormFieldDiv input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, input[type=number]:focus{
    border: 2px solid blue;
    background-color: cornsilk;
}



.FormActionDiv{
    text-align: center;
}



.FormActionDiv button,  input[type=reset], input[type=button],input[type=submit] {
    padding: 5px 15px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: blue ;
    border: none;
    border-radius: 15px;
    box-shadow: 0 7px #999;
    margin: 10px;
  }

.FormActionDiv button:hover, input[type=reset]:hover, input[type=button]:hover,input[type=submit]:hover {background-color: #3e8e41}

.FormActionDiv button:active, input[type=reset]:active, input[type=button]:active,input[type=submit]:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}



.NewUserRegsitrationForm .errorDiv{
    color:red;
    font-weight: bold;
    text-align: justify;
    font-size: small;
  }

.NewUserRegsitrationForm .msgDiv{
    color: green;
    font-weight: bold;
    text-align: justify;
    font-size: small;
}