
.otherSocietyDetailsMainDiv {
    width: 90%;
    height: 60vh;
    margin-left: 18%;
    margin-top:2%;
    margin-bottom: 5%;
    /* background-color: aqua; */
    text-align: center;
    padding: 10px;
    font-size: medium;
    
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
}

.otherSocietyDetailsMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:10px;
    border: 5px solid blue;
}

.otherSocietyDetailsMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.otherSocietyDetailsMainDiv  .FormFieldDiv input,
.otherSocietyDetailsMainDiv .FormFieldDiv input[type=text], 
.otherSocietyDetailsMainDiv .FormFieldDiv select, 
.otherSocietyDetailsMainDiv .FormFieldDiv input[type=number], 
.otherSocietyDetailsMainDiv .FormFieldDiv input[type=date], 
.otherSocietyDetailsMainDiv .FormFieldDiv input[type=email], 
.otherSocietyDetailsMainDiv .FormFieldDiv input[type=password] {
    padding: 12px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;
    font-weight: bold;
    width: 300px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.FormFieldDiv textarea{
    border-radius: 8px;
    
}

.otherSocietyDetailsMainDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.otherSocietyDetailsMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
    
}

.FormFieldDiv label {
    
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.otherSocietyDetailsMainDiv legend {
    text-align: justify;
    color: blue;
}

.gridRow{
    display: flex;
}

.gridCol{
    display: inline-block;
    margin: 10px;
}

.addSocietyDetailsTable {
    width:150%;
    border: 1px solid blue;
    border-collapse: collapse;
    padding: 10px;
   
}

.addSocietyDetailsTable thead td{
    background-color: blue;
    color:white;
    border: 1px solid white;
}

.addSocietyDetailsTable td{
    padding:5px;
    text-align: justify;
}

.addSocietyDetailsTableDiv{
    overflow-y: auto;
    margin-left:2%;
 
}

