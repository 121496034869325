.membershipFeesPaymentTabMainDiv{
    width: 90%;
    margin-top:10px;
    margin-left: 5%;
    font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: small;
    font-weight: bold;
}

/* .membershipApplicationStatusTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
}

.membershipApplicationStatusTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.membershipApplicationStatusTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.membershipApplicationStatusTable thead td{
    padding:15px;
    
}

.membershipApplicationStatusTable tbody td{
    padding:15px;
    border: 1px solid blue;
} */

.payment-status-div{
    text-align: left;
}

.payment-status-div select{
    padding:5px;
    border-radius: 8px;
    margin-left: 5px;
}