.ReveiwPaySlipMainDiv{
    width: 80%;
    height: 60vh;
    margin-left: 17%;
    margin-top: 3%;
    /* background-color: aqua; */
    text-align: justify;
    padding: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
    font-weight: bold;
}

.ReveiwPaySlipMainDiv fieldset{
    width: inherit;
    border-radius: 8px;
    background-color: whitesmoke;
    border: 5px solid blue;
    overflow-y: auto;
    height: 60vh;
    
}

.ReveiwPaySlipMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

