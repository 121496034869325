
.memberDashMainDiv {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: white;
}

.memberDashBoardBody{
    width: 100%;
    min-height: 84%;
    display: flex;
    flex-direction: row;
    
}

MemberDashSideNav, MemberDashWorkSpace{
    display: inline-block;
    
}


