.ManageMessagesToRBCTCSMainDiv{
    width: 98%;
    margin-top:1%;
    margin-left: 1%;
    font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: small;
    font-weight: bold;
}

.ManageMessagesToRBCTCSMainDiv fieldset{
    height:82vh;
    overflow-y: auto;
    border: 5px solid blue;
    border-radius: 8px;
    padding:10px;
    background-color: ghostwhite;
}

.ManageMessagesToRBCTCSMainDiv fieldset legend{
    background-color: blue;
    border-radius: 8px;
    color:whitesmoke;
    padding: 10px;
}


.ManageMessagesToRBCTCSMainDiv .FormFieldDiv input,
.ManageMessagesToRBCTCSMainDiv .FormFieldDiv input[type=text], 
.ManageMessagesToRBCTCSMainDiv .FormFieldDiv select, 
.ManageMessagesToRBCTCSMainDiv .FormFieldDiv input[type=number], 
.ManageMessagesToRBCTCSMainDiv .FormFieldDiv input[type=date], 
.ManageMessagesToRBCTCSMainDiv .FormFieldDiv input[type=email], 
.ManageMessagesToRBCTCSMainDiv .FormFieldDiv input[type=password] {
    min-width:180px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
}

.ManageMessagesToRBCTCSMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.ViewMessageSendReplyDialogDiv .FormFieldDiv textarea{
    border: 5px solid blue;
    padding:10px;
    border-radius: 8px;
    font-family: 'verdana';
    font-size: 14px;
    
}

.ManageMessagesToRBCTCSMainDiv table{
    width: 100%;
    font-size: 13px;
    text-align: center;
}

.ManageMessagesToRBCTCSMainDiv table thead{
   background-color: blue;
   color:white;
   padding:15px;
}

.ManageMessagesToRBCTCSMainDiv table thead td, .ManageMessagesToRBCTCSMainDiv table tbody td{
    
    padding:10px;
    border: 1px solid blue;
 }

