.RegistrationDetailsViewerMainDiv{

    width: fit-content;
    height: 60vh;
    padding:30px;
    margin-top: 2%;
    margin-left: 25%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size:medium;
    font-weight: bold;
    color: black;

}


.RegistrationDetailsViewerMainDiv fieldset {
    width: inherit;
    border-radius: 8px;
    background-color: whitesmoke;
    border: 5px solid blue;
    overflow-y: auto;
    height: 60vh;
}

.RegistrationDetailsViewerMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.RegistrationDetailsViewerMainDiv table{
    
    padding:30px;
}

.RegistrationDetailsViewerMainDiv table td{
    padding:15px;
    border:1px solid blue;
    border-radius: 8px;
}

.RegistrationDetailsViewerMainDiv table> tbody > tr > td:first-child{
    background-color: blue;
    color:white;
}

.RegistrationDetailsViewerMainDiv img{
    width: 150px;
    height:150px;
}


.RegistrationDetailsViewerMainDiv .FormFieldDiv label {
    font-family: 'courier';
    font-weight: bold;
    font-size: 18px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.isLoadingDiv{
    padding: 50px;
    color:blue;
    font-size:x-large;
}
