/* MembershipApplicationStatusExplanation.css */

.container {
    max-width: 80%;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-family: monospace;
    font-size: xx-large;
    text-align: justify;
  }
  
  .explanation {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .full-form {
    font-size: 22px;
    color: #666;
  }
  
  /* Add more styles as needed */
  