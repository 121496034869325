.UploadRBCTCSOrdersCirculgarsMainDiv{
    width: fit-content;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: small;
    font-weight: bold;
}

.UploadRBCTCSOrdersCirculgarsMainDiv fieldset{
    width: fit-content;
    border: 5px solid blue;
    border-radius: 8px;
    padding:30px;
    background-color: ghostwhite;
}

.UploadRBCTCSOrdersCirculgarsMainDiv fieldset legend{
    background-color: blue;
    border-radius: 8px;
    color:whitesmoke;
    padding: 10px;
}


.UploadRBCTCSOrdersCirculgarsMainDiv .FormFieldDiv input,
.UploadRBCTCSOrdersCirculgarsMainDiv .FormFieldDiv input[type=text], 
.UploadRBCTCSOrdersCirculgarsMainDiv .FormFieldDiv select, 
.UploadRBCTCSOrdersCirculgarsMainDiv .FormFieldDiv input[type=number], 
.UploadRBCTCSOrdersCirculgarsMainDiv .FormFieldDiv input[type=date], 
.UploadRBCTCSOrdersCirculgarsMainDiv .FormFieldDiv input[type=email], 
.UploadRBCTCSOrdersCirculgarsMainDiv .FormFieldDiv input[type=password],
.UploadRBCTCSOrdersCirculgarsMainDiv .FormFieldDiv textarea {
    min-width:180px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
}

.UploadRBCTCSOrdersCirculgarsMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.UploadRBCTCSOrdersCirculgarsMainDiv .FormActionDiv{
    margin: 15px;
}

