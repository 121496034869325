.RBCTCSMembershipDetailsViewByStaffOrAdminMainDiv{
    padding:15px;
    margin-top:4%;
    margin-left:10%;
    z-index: 5;
    font-size: small;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
   
}

.RBCTCSMembershipDetailsViewByStaffOrAdminMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.RBCTCSMembershipDetailsViewByStaffOrAdminMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.RBCTCSMembershipDetailsViewByStaffOrAdminMainDiv table{
    
    padding:30px;
}

.RBCTCSMembershipDetailsViewByStaffOrAdminMainDiv table td{
    padding:15px;
    border:1px solid blue;
    border-radius: 8px;
}

.RBCTCSMembershipDetailsViewByStaffOrAdminMainDiv table> tbody > tr > td:first-child{
    background-color: blue;
    color:white;
}

.RBCTCSMembershipDetailsViewByStaffOrAdminMainDiv img{
    width: 150px;
    height:150px;
}

