.FooterRBCTCS {
    padding: 10px;
}

.footerNote{
    padding: 5px;
    text-align: center;
    color: whitesmoke;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight:bold;
}