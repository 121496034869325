.MemberBankDetailsViewByStaffOrAdminMainDiv{
    padding:25px;
    width: 45%;
    margin:auto;
    z-index: 5;
    font-size: small;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    
}

.MemberBankDetailsViewByStaffOrAdminMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.MemberBankDetailsViewByStaffOrAdminMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.MemberBankDetailsViewByStaffOrAdminMainDiv table{
    
    padding:30px;
}

.MemberBankDetailsViewByStaffOrAdminMainDiv table td{
    padding:15px;
    border:1px solid blue;
    border-radius: 8px;
}

.MemberBankDetailsViewByStaffOrAdminMainDiv table> tbody > tr > td:first-child{
    background-color: blue;
    color:white;
}

.MemberBankDetailsViewByStaffOrAdminMainDiv img{
    width: 150px;
    height:150px;
}

