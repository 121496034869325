.loanBond2MainDiv{
    width: 90%;
    height: 80vh;
    padding:30px;
    margin-left:6%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;
    font-weight: bold;
    color: black;
}

.loanBond1HeaderDiv{
    text-align: center;  
}


.loanBond2MainDiv fieldset {
    width: inherit;
    border-radius: 8px;
    background-color: whitesmoke;
    border: 5px solid blue;
    overflow-y: auto;
    height: 80vh;
}

.loanBond2MainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.loanBond2ContentDiv{
    width: fit-content;
        
}

.loanBond2ContentDiv p{
    text-align: justify;   
}