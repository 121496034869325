.VerifyMembershipApplicationsMainDiv{
    width: 90%;
    margin-top:1%;
    margin-left: 5%;
    font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: small;
    font-weight: bold;
}

.VerifyMembershipApplicationsMainDiv fieldset{
    border: 5px solid blue;
    border-radius: 8px;
    padding:10px;
    background-color: ghostwhite;
    overflow: auto;
}

.VerifyMembershipApplicationsMainDiv fieldset legend{
    background-color: blue;
    border-radius: 8px;
    color:whitesmoke;
    padding: 10px;
}

.membershipApplicationStatusTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
    font-weight: bolder;
}

.membershipApplicationStatusTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.membershipApplicationStatusTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.membershipApplicationStatusTable thead td{
    padding:15px;
    
}

.membershipApplicationStatusTable tbody td{
    padding:15px;
    border: 1px solid blue;
}

.adminDisplayDiv{
    margin:0 auto;
    border: 4px solid blue;
    color:blue;
    padding: 20px;
    font-size: large;
    font-weight: bold;
}
.adminDisplayDiv table{
    width: 100%;
    
}
.adminDisplayDiv table tbody tr td{
    border: 1px solid blue;
    padding:15px;
    width: fit-content;
}