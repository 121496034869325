
.officialDetailsMainDiv {
    width: 80%;
    height: 60vh;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 15%;
    /* background-color: aqua; */
    text-align: center;
    padding: 20px;
    font-size: medium;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
   
}

.officialDetailsMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.officialDetailsMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.officialDetailsMainDiv .FormFieldDiv input, 
.officialDetailsMainDiv .FormFieldDiv select, 
.officialDetailsMainDiv .FormFieldDiv input[type=number], 
.officialDetailsMainDiv .FormFieldDiv input[type=text],   
.officialDetailsMainDiv .FormFieldDiv input[type=date], 
.officialDetailsMainDiv .FormFieldDiv input[type=email]{
    padding: 12px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    font-size: medium;
    width: 220px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.officialDetailsMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.officialDetailsMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
  
}

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.officialDetailsMainDiv legend {
    text-align: justify;
    color: blue;
}

.gridRow{
    display: flex;
}

.gridCol{
    display: inline-block;
    margin: 10px;
}