.membershipApplicationPreviewMainDiv{
    width: 85%;
    margin-top: 2%;
    margin-left: 10%;
    margin-bottom: 2%;
    padding: 10px;
    font-size: small;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
}

.membershipApplicationPreviewMainDiv fieldset{
    width: inherit;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:10px;
    border: 5px solid blue;
    height: 60vh;
    overflow-y: auto;
}

.membershipApplicationPreviewMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}
.detailseditbuttondiv{
    width: 100%;
   text-align: right;
}



.societyDetailsTable, .nomineeDetailsTable{
    width: 100%;
    border: 1px solid blue;
    border-collapse: collapse;
    padding: 10px;
}

.societyDetailsTable thead td, .nomineeDetailsTable thead td{
    background-color: blue;
    color:white;
    border: 1px solid white;
}

.societyDetailsTable, .nomineeDetailsTable td{
    padding:5px;
    text-align: justify;
}

.MuiAccordionSummary-root{
    background-color: ghostwhite;
    color: blue;
    text-align: justify;
}

.FormActionDiv{
    text-align: center;
}



.FormActionDiv button,  input[type=reset], input[type=button],input[type=submit] {
    padding: 10px 15px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: blue ;
    border: none;
    border-radius: 15px;
    box-shadow: 0 7px #999;
    margin: 10px;
  }

.FormActionDiv button:hover, input[type=reset]:hover, input[type=button]:hover,input[type=submit]:hover {background-color: #3e8e41}

.FormActionDiv button:active, input[type=reset]:active, input[type=button]:active,input[type=submit]:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}


