.MemberNomineesDetailsViewByStaffOrAdminMainDiv{
    padding:10px;
    width: 80%;
    margin: auto;
    z-index: 5;
    font-size: small;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: center;
   
}

.MemberNomineesDetailsViewByStaffOrAdminMainDiv fieldset{
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:10px;
    border: 5px solid blue;
    height:70vh;
    overflow-y: auto;
    text-align: justify;
    
}

.MemberNomineesDetailsViewByStaffOrAdminMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
   
}

.MemberNomineesDetailsViewByStaffOrAdminMainDiv table{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
    
}


.MemberNomineesDetailsViewByStaffOrAdminMainDiv thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.MemberNomineesDetailsViewByStaffOrAdminMainDiv tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.MemberNomineesDetailsViewByStaffOrAdminMainDiv thead td{
    padding:10px;
    
}

.MemberNomineesDetailsViewByStaffOrAdminMainDiv tbody td{
    padding:10px;
    border: 1px solid blue;
}

.MemberNomineesDetailsViewByStaffOrAdminMainDiv img{
    width: 150px;
    height:150px;
}

