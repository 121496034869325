.myButton{
  color: red;
  background-color: rgb(236, 240, 243);
}

.noDataFoundTextDiv{
  font-size: xx-large;
  font-weight: bold;
  color: blue;
  padding: 250px;
  text-decoration: underline;
}

.errorDiv{
  color:red;
  font-weight: bold;
}

.msgDiv{
  color:green;
  font-weight: bold;
}

.justifyContent{
  text-align: justify;
}

.centerContent{
  text-align: center;
}

.pdfDisplayerDialogContentDiv{
  width: 100%;
}

.pdfDisplayerDialogContentDiv iframe{
  width: 100%;
  border: 5px solid blue;
  height:75vh;
}

.titlePDFFile{
  margin-right: auto;
  padding-left: 10px;
  font-weight: bold;
  color:blue;
}

.titleDialogBox{
  margin-right: auto;
  padding-left: 10px;
  font-weight: bold;
  color:white;
  background-color: blue;
  padding:5px;
  border-radius: 8px;
  margin-left: auto;
  width: 75%;
  text-align: center;
  font-family: 'verdana';
    font-size: 14px;
}


 /* Hide scrollbar for Chrome, Safari and Opera */
 .scrollWithoutScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollWithoutScrollBar {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}