
.membershipDocsUploadMainDiv {
    width: 65%;
    height: 60vh;
    margin: auto;
    margin-top:2%;
    margin-bottom:2%;
    /* background-color: aqua; */
    text-align: justify;
    padding: 20px;
    font-size: large;
    font-family:'Courier New', Courier, monospace;
    font-weight: bold;
   
}

.membershipDocsUploadMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.membershipDocsUploadMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.membershipDocsUploadMainDiv .FormFieldDiv input,
.membershipDocsUploadMainDiv .FormFieldDiv input[type=text], 
.membershipDocsUploadMainDiv .FormFieldDiv input[type=number], 
.membershipDocsUploadMainDiv .FormFieldDiv input[type=date], 
.membershipDocsUploadMainDiv .FormFieldDiv input[type=email], 
.membershipDocsUploadMainDiv .FormFieldDiv input[type=password] {
    min-width:180px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 17px;
    font-weight:bold;
}

.membershipDocsUploadMainDiv .FormFieldDiv select{
    min-width:220px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 17px;
    font-weight:bold;
}

.membershipDocsUploadMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.membershipDocsUploadMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
    
}

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.membershipDocsUploadMainDiv legend {
    text-align: justify;
    color: blue;
}

.membershipDocsUploadMainDiv .paymentTable{
    border: 1px solid blue;
    text-align: justify;
    color: blue;
   
}

.membershipDocsUploadMainDiv .paymentTable thead{
    color:whitesmoke;
    background-color: blue;
}

.membershipDocsUploadMainDiv .paymentTable td{
       padding: 8px;
       border: 1px solid blue;
}

.gridRow{
    display: flex;
}

.gridCol{
    display: inline-block;
    margin: 10px;
}

.uploadFileTable{
     border-collapse: collapse;
     border: 1px solid black;
}