.sharesStatementDiv{
    padding-left: 20px;
    padding-right: 20px;
    font-size: x-large;
    font-weight: bold;

}

.sharesLedgerTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size:small;
}

.sharesLedgerTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.sharesLedgerTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.sharesLedgerTable thead td{
    padding:15px;
    
}

.sharesLedgerTable tbody td{
    padding:15px;
    border: 1px solid blue;
}