.suretyDetailsTableDiv{
    padding: 5px;
    width: fit-content;
}

.suretyDetailsTableDiv .suretyDetailsTable{
    padding:5px;
    text-align: center;
    border-collapse: collapse;
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

}

.suretyDetailsTableDiv .suretyDetailsTable thead>tr>td{
    padding:5px;
    text-align: center;
    background-color: blue;
    color: beige;
}

.suretyDetailsTableDiv .suretyDetailsTable tbody>tr>td {
    color: blue;
}