.AvtarProfileWidget {
  width: fit-content;
  height: fit-content;
  display: flex;
  margin: 10px;
  background-color: ghostwhite;
  border: 2px solid blue;
  border-radius: 8px;
  z-index: 4;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.AvtarImageDiv {
  display: inline-block;
  margin: 5px;
  
}

.AvtarImageDiv img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  margin-right:15px;
  margin-top: 4px;
  border: 1px solid black;
}

.AvtarInfoDiv {
  width: 190px;
  display: inline-block;
  color: blue;
  padding-top: 15px;
}

.AvtarInfoTextDiv{
  font-size: 14px;
  font-weight: bold;
}