.stepper-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 90%;
    margin: auto;
    margin-top: 10px;
    background-color: lightsteelblue;
  }
  
  .stepper-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .step {
    padding: 10px 20px;
    border-radius: 4px;
    background-color: #eee;
    cursor: pointer;
  }
  
  .active-step {
    background-color: #007bff;
    color: #fff;
  }
  
  .step-content {
    margin-bottom: 20px;
    background-color: ghostwhite;
    padding: 20px;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  