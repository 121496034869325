.loanDisbursmentReportMainDiv{
    width: 98%;
    margin-top:1%;
    margin-left: 1%;
    font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: small;
    font-weight: bold;
}

.loanDisbursmentReportMainDiv fieldset{
    height:82vh;
    overflow-y: auto;
    border: 5px solid blue;
    border-radius: 8px;
    padding:10px;
    background-color: ghostwhite;
}

.loanDisbursmentReportMainDiv fieldset legend{
    background-color: blue;
    border-radius: 8px;
    color:whitesmoke;
    padding: 10px;
}

.LDRFiltersSearchDiv{
    width:100%;
    background-color:lightgrey ;
    color: blue;
    border-radius: 8px;
    text-align: right;
}

.LDRContentTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
}

.LDRContentTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.LDRContentTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.LDRContentTable thead td{
    padding:10px;
    
}

.LDRContentTable tbody td{
    padding:10px;
    border: 1px solid blue;
}


.loanDisbursmentReportMainDiv .FormFieldDiv input,
.loanDisbursmentReportMainDiv .FormFieldDiv input[type=text], 
.loanDisbursmentReportMainDiv .FormFieldDiv select, 
.loanDisbursmentReportMainDiv .FormFieldDiv input[type=number], 
.loanDisbursmentReportMainDiv .FormFieldDiv input[type=date], 
.loanDisbursmentReportMainDiv .FormFieldDiv input[type=email], 
.loanDisbursmentReportMainDiv .FormFieldDiv input[type=password] {
    min-width:180px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
}

.loanDisbursmentReportMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

