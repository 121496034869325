.UpdatePasswordMainDiv{
    width: fit-content;
    padding: 50px;
    border-radius: 8px;
    margin: auto;
}

.UpdatePasswordMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
    color:blue;
    text-align: justify;
    
}

.UpdatePasswordMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}


.UpdatePasswordMainDiv .FormFieldDiv {
    text-align: center;
    padding: 5px;
}

.UpdatePasswordMainDiv .FormFieldDiv input[type=text],
.UpdatePasswordMainDiv .FormFieldDiv input[type=email], 
.UpdatePasswordMainDiv .FormFieldDiv input[type=password], 
.UpdatePasswordMainDiv .FormFieldDiv input[type=number]
{
    margin: auto;
    width:300px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
}

.UpdatePasswordMainDiv .FormFieldDiv input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, input[type=number]:focus{
    border: 2px solid blue;
    background-color: cornsilk;
}



.FormActionDiv{
    text-align: center;
}



.FormActionDiv button,  input[type=reset], input[type=button],input[type=submit] {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: blue ;
    border: none;
    border-radius: 15px;
    box-shadow: 0 7px #999;
    margin: 15px;
  }

.FormActionDiv button:hover, input[type=reset]:hover, input[type=button]:hover,input[type=submit]:hover {background-color: #3e8e41}

.FormActionDiv button:active, input[type=reset]:active, input[type=button]:active,input[type=submit]:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

