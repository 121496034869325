.timeline {
  padding: 10px 0;
  margin-right: 0;
  padding-right: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.timeline-item {
  position: relative;
  width: 200px;
  padding: 20px;
  margin: 10px 0;
  background-color:lightgray;
  color:blue;
  border-radius: 5px;
  text-align: justify;
  font-size: small;
}

.timeline-item::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333;
}

.timeline-item:first-child::before {
  top: 0;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-content h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.timeline-content p {
  margin: 0;
}
