.PortalWithoutLogin {
    scroll-behavior: smooth;
    width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    /* border: 5px solid violet; */
}

.bodyContentDiv{
  width:  100%;
  /* border: 1px solid red; */
  
}


.bodyContentDiv > .avtarProfileDiv{
    height: 120vh;
    width:30%;
    padding:10px;
    overflow-y: scroll;
    
}

.officeBearerAvtarListHeaderDiv{
  background-color: ghostwhite;
  color:blue;
  padding:10px;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;
  width: 67%;
  margin-left:10px;
  margin-top: 0px;
  z-index: 4;
  position: relative;
  border: 2px solid blue;
  
}



  /* Hide scrollbar for Chrome, Safari and Opera */
  .avtarProfileDiv::-webkit-scrollbar {
      display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .avtarProfileDiv {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .bodyContentDiv > .outletDiv{
    width: 70%;
    margin-left: auto;
    /* border: 5px solid red; */
   }
  /* marquee */

  .marquee-wrapper-css {
    
    animation: marquee 60s linear infinite;
    animation-direction: alternate-reverse;
  }
  
  @keyframes marquee {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  

  