
.PaymentsForLoanMainDiv {
    width: 85%;
    height: 60vh;
    margin-left: 15%;
    margin-top:2%;
    margin-bottom:2%;
    /* background-color: aqua; */
    text-align: center;
    padding: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
    font-weight: bold;
}

.PaymentsForLoanMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.PaymentsForLoanMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
  }

.PaymentsForLoanMainDiv .FormFieldDiv input,
.PaymentsForLoanMainDiv .FormFieldDiv input[type=text], 
.PaymentsForLoanMainDiv .FormFieldDiv select, 
.PaymentsForLoanMainDiv .FormFieldDiv input[type=number], 
.PaymentsForLoanMainDiv .FormFieldDiv input[type=date], 
.PaymentsForLoanMainDiv .FormFieldDiv input[type=email], 
.PaymentsForLoanMainDiv .FormFieldDiv input[type=password] {
    min-width:180px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
}

.PaymentsForLoanMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.PaymentsForLoanMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
    
}

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.PaymentsForLoanMainDiv legend {
    text-align: justify;
    color: blue;
}

.gridRow{
    display: flex;
}

.gridCol{
    display: inline-block;
    margin: 10px;
}

.sharesNotRequiredDiv{
    margin-top: 15px;
    padding: 10px;
    background-color: blue;
    color:white;
    border-radius: 8px;
    font-size: large;
    text-align: justify;
}