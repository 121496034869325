.LDRContentTable2{
    margin-left:10%;
    width:80%;
    border: 1px solid black;
    border-collapse: collapse;
}

.LDRContentTable2 thead{
    background-color: whitesmoke;
    color:black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.LDRContentTable2 tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.LDRContentTable2 thead td{
    padding:5px;
    border: 1px solid black;
}

.LDRContentTable2 tbody td{
    padding:5px;
    border: 1px solid black;
}

.LDRActionsDiv{
    background-color: lightblue;
    color:white;
    padding: 1px;
    text-align: right;
}

.divAddressee, .divSalutation, .divPara,  .divOfficeUse{
    padding:5px;
    margin-left: 5%;margin-right: 5%;
}

.divSign{
    padding: 10px;
    margin-left: 5%;margin-right: 5%;
}