.sideNavBar {
  display: inline-block;
  overflow-y: scroll;
  border-right: 2px solid lightblue;
  height: 130vh;
  width: 400px;
  background-color: whitesmoke;
}

.sideNavBar li {
  list-style: none;
  color: "blue";
  padding: "5px";
  font-size: 16px;
  cursor: "pointer";
  margin-right:10px;
  
}

.sideNavBar ul{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  
}

.sideNavBar li:hover {
  background-color: antiquewhite;
  padding: 5px;
}