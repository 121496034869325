.EDRFiltersSearchDiv{
    width:100%;
    background-color:lightgrey ;
    color: blue;
    border-radius: 8px;
    text-align: right;
    vertical-align: middle;
}

.EDRFiltersSearchDiv span{
    padding: 5px;
    margin-left: 2%; 
    margin-right: 3%;
}

.EDRFiltersSearchDiv .FormFieldDiv input,
.EDRFiltersSearchDiv .FormFieldDiv input[type=text], 
.EDRFiltersSearchDiv .FormFieldDiv select, 
.EDRFiltersSearchDiv .FormFieldDiv input[type=number], 
.EDRFiltersSearchDiv .FormFieldDiv input[type=date], 
.EDRFiltersSearchDiv .FormFieldDiv input[type=email], 
.EDRFiltersSearchDiv .FormFieldDiv input[type=password] {
    margin-top: 5px;
    min-width:180px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
}

.EDRFiltersSearchDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}


.EDRFiltersSearchDivTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
}

.EDRFiltersSearchDivTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.EDRFiltersSearchDivTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.EDRFiltersSearchDivTable thead td{
    padding:15px;
    
}

.EDRFiltersSearchDivTable tbody td{
    padding:15px;
    border: 1px solid blue;
}

.editButton input[type=button]{
    padding:3px;
    float:right;
}


.EdiLoanDetailstDialogContentDiv{
    text-align: center;
    padding:10px;
}

.EdiLoanDetailstDialogContentDiv .FormFieldDiv input[type=text], 
.EdiLoanDetailstDialogContentDiv .FormFieldDiv input[type=date],
.EdiLoanDetailstDialogContentDiv .FormFieldDiv input[type=email],
.EdiLoanDetailstDialogContentDiv .FormFieldDiv input[type=number] 
{
    padding: 12px;
    font-family: 'courier';
    font-weight: bold;
    font-size: small;
    width: 200px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.EdiLoanDetailstDialogContentDiv .FormFieldDiv input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus, input[type=email]:focus {
    background-color: cornsilk;
}