
.nomineesDetailsUpdaterMainDiv {
    width: 80%;
    height: 60vh;
    margin-top:2%;
    margin-left: 20%;
    margin-bottom: 2%;
    /* background-color: aqua; */
    text-align: center;
    padding: 5px;
    font-size: medium;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
}

.nomineesDetailsUpdaterMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:10px;
    border: 5px solid blue;
}

.nomineesDetailsUpdaterMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.nomineesDetailsUpdaterMainDiv .FormFieldDiv input, 
.nomineesDetailsUpdaterMainDiv .FormFieldDiv input[type=text], 
.nomineesDetailsUpdaterMainDiv .FormFieldDiv input[type=number], 
.nomineesDetailsUpdaterMainDiv .FormFieldDiv input[type=date], 
.nomineesDetailsUpdaterMainDiv .FormFieldDiv input[type=email], 
.nomineesDetailsUpdaterMainDiv .FormFieldDiv input[type=password],
.nomineesDetailsUpdaterMainDiv .FormFieldDiv select
 {
    padding: 12px;
    font-family: 'courier';
    font-weight: bold;
    font-size: medium;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    width: 180px;
    border-radius: 8px;
    margin: 2px;
    border: 1px solid blueviolet;
}

.nomineesDetailsUpdaterMainDiv .FormFieldDiv textarea{
    padding: 12px;
    font-family: 'courier';
    font-weight: bold;
    font-size: medium;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    width: 180px;
    border-radius: 8px;
    margin: 2px;
    border: 1px solid blueviolet;
}

.nomineesDetailsUpdaterMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.nomineesDetailsUpdaterMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
 }

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.nomineesDetailsUpdaterMainDiv legend {
    text-align: justify;
    color: blue;
}

.gridRow{
    display: flex;
}

.gridCol{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

.addnomineeDetailsTableDiv{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    height:280px;
    overflow-y: auto;
}

.addnomineeDetailsTable {
    width:150%;
    border: 1px solid blue;
    border-collapse: collapse;
    padding: 10px;
    
}

.addnomineeDetailsTable thead td{
    background-color: blue;
    color:white;
    border: 1px solid white;
}

.addnomineeDetailsTable td{
    padding:5px;
    text-align: justify;
}

.addnomineeDetailsTableDiv{
    overflow-y: auto;
}

