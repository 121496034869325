.loginBoxDiv {
    width: fit-content;
    border-radius: 8px;
    z-index: 2;
    margin: auto;
}

.loginBoxDiv fieldset{

    color:blue;
    background-color: ghostwhite;
    text-align: left;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    padding: 70px;
    border: solid 5px blue;
    border-radius: 8px;
}

.loginBoxDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:ghostwhite;
    border-radius: 8px;
    border: 5px solid blue;
}


.loginBoxDiv table{
    width: 100%;
}

.loginBoxDiv td{
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}

.loginBoxDiv td:nth-child(even){
    text-align: right;
}

.loginBoxDiv input[type=text], .loginBoxDiv input[type=password], .loginBoxDiv input{
    width:400px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-size: medium;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color:blue;
}

/* {
    width:320px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-size: medium;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color:blue;
} */

.loginBoxDiv input[type=text]:focus, select:focus, input[type=password]:focus{
    border: 2px solid blue;
    background-color: cornsilk;
    
}

.loginBoxDiv label{
     padding: 10px;
}

.loginBoxDiv table{
  width: 100%;
  
}

.loginBoxDiv table>tbody>tr>td{
    text-align: center;
   
}

.actionLogInButtons{
    width: 100%;
}

.newRegButtons{
    color: white;
}


.loginInputs{
    color: chartreuse;
}