.ViewMembershipApplicationStatusMainDiv{
    width: 90%;
    margin-top:3%;
    margin-left: 5%;
    font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: small;
    font-weight: bold;
}

.ViewMembershipApplicationStatusMainDiv fieldset{
    border: 5px solid blue;
    border-radius: 8px;
    padding:10px;
    background-color: ghostwhite;
}

.ViewMembershipApplicationStatusMainDiv fieldset legend{
    background-color: blue;
    border-radius: 8px;
    color:whitesmoke;
    padding: 10px;
}

.ViewMembershipApplicationStatusMainDiv .noApplicationSubmitted{
    font-size: x-large;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: justify;
    color: blue;
}

.membershipApplicationStatusTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
    text-align: center;
}

.membershipApplicationStatusTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.membershipApplicationStatusTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.membershipApplicationStatusTable thead td{
    padding:15px;
    
}

.membershipApplicationStatusTable tbody td{
    padding:15px;
    border: 1px solid blue;
}