
.bankDetailsMainDiv {
    width: 85%;
    height: 60vh;
    margin-left: 15%;
    margin-top:2%;
    margin-bottom:2%;
    /* background-color: aqua; */
    text-align: center;
    padding: 20px;
    font-size: medium;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
   
}

.bankDetailsMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.bankDetailsMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.bankDetailsMainDiv .FormFieldDiv input,
.bankDetailsMainDiv .FormFieldDiv input[type=text], 
.bankDetailsMainDiv .FormFieldDiv select, 
.bankDetailsMainDiv .FormFieldDiv input[type=number], 
.bankDetailsMainDiv .FormFieldDiv input[type=date], 
.bankDetailsMainDiv .FormFieldDiv input[type=email], 
.bankDetailsMainDiv .FormFieldDiv input[type=password] {
    min-width:180px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-size: medium;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
}

.bankDetailsMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.bankDetailsMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
    
}

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.bankDetailsMainDiv legend {
    text-align: justify;
    color: blue;
}

.gridRow{
    display: flex;
}

.gridCol{
    display: inline-block;
    margin: 10px;
}