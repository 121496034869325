.LDRContentTable input[type=checkbox]{
    padding: 2px;
}

.LDRContentTable .actionCol{
    text-align: center;
}

.LDRFiltersSearchDiv span{
    margin-left: 20px;
    margin-right: 20px;
}

.LDRFiltersSearchDiv input[type=button] {
    float: right;
}

.alternateReportViewText{
    text-align: center;
    padding: 75px;
    color: blue;
    font-size: x-large;
}