.WriteToRBCTCS {
    border-radius: 8px;
    width:fit-content;
    color:blue;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    font-weight:bold;
    text-align: left;
    margin: auto;
}

.WriteToRBCTCS  fieldset{
    color:blue;
    background-color: ghostwhite;
    text-align: left;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    font-weight:bold;
    border-radius: 8px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 80px;
    padding-right: 80px;
    border: solid 5px blue;
    border-radius: 8px;
}

.WriteToRBCTCS fieldset legend{
    padding:10px;
    background-color: blue;
    color:ghostwhite;
    border-radius: 8px;
    border: 5px solid blue;
}

.WriteToRBCTCS td{
    text-align: center;
    padding: 5px;
}

.actionButton{
    text-align: right;
}

.WriteToRBCTCS select, .WriteToRBCTCS input[type=text], .WriteToRBCTCS input[type=password]{
    width:400px;
    padding:15px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color:blue;
    margin: 5px;
}

.WriteToRBCTCS textarea{
    width:500px;
    padding:15px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
    font-weight: bold;
    color:blue;
    margin: 5px;
}

.WriteToRBCTCS input[type=text]:focus, select:focus, input[type=password]:focus{
    border: 2px solid blue;
    background-color: cornsilk;
    
}