
.dataDisplayerTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.dataDisplayerTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.dataDisplayerTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.dataDisplayerTable thead th{
    padding:5px;
    
}

.dataDisplayerTable tbody td{
    padding:5px;
    border: 1px solid blue;
    text-align: center;
}

.quickSearchBoxDiv{
    text-align: right;
}

.quickSearchBoxDiv input[type=text] {
    padding:5px;
    border-radius: 8px;
    margin: 4px;
}


/* pagination css */

.pagination {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 3px 5px;
    margin: 0 2px;
    border: none;
    background-color: lightblue;
    color: brown;
    cursor: pointer;
    outline: none;
  }
  
  .pagination button:hover {
    background-color: blue;
    color: white;
  }
  
  .pagination button:disabled {
    cursor: default;
    opacity: 0.6;
  }
  
  .pagination .active {
    background-color: blue;
    color: white;
  }