.EDRContentTable2{
    margin-left:5%;
    width:90%;
    border: 1px solid black;
    border-collapse: collapse;
}

.EDRContentTable2 thead{
    background-color: whitesmoke;
    color:black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.EDRContentTable2 tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.EDRContentTable2 thead td{
    padding:5px;
    border: 1px solid black;
   
}

.EDRContentTable2 tbody td{
    padding:5px;
    border: 1px solid black;
    
}

.divClosureStars{
    text-align: center;
    padding: 20px;
}