.PhotoSignatureUploaderMainDiv{
    padding:25px;
    max-width:50%;
    margin-left:20%;
    font-size: small;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
}

.PhotoSignatureUploaderMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:20px;
    border: 5px solid blue;
}

.PhotoSignatureUploaderMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}


.imageHolderDiv .imageDisplay{
    display: block;
    max-width:175px;
    max-height:210px;
    width: auto;
    height: auto;
    
}

.imageHolderDiv .signDisplay{
    display: block;
    max-width:230px;
    max-height:95px;
    width: auto;
    height: auto;
   
}

.imageHolderDiv{
    padding:5px;
    text-align: center;
}



.PhotoSignatureUploaderMainDiv table{
    
    padding:5px;
}

.PhotoSignatureUploaderMainDiv table td{
    padding:10px;
    border:1px solid blue;
    border-radius: 8px;
    text-align: center;
}



