
.membershipInstructionsMainDiv {
    width: 80%;
    
    padding:30px;
    margin-left: 15%;
    font-size: x-large;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
}

.membershipInstructionsMainDiv fieldset{
    width: inherit;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:10px;
    border: 5px solid blue;
    height: 50vh;
    text-align: justify;
    color: blue;
}

.membershipInstructionsMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.membershipInstructionsMainDiv legend {
    text-align: justify;
    color: blue;
}

