.dashBoardHeaderDiv {
    background-color: blue;
    top: 0px;
    right: 0px;
    left: 0px;
    padding: 15px;
    z-index: 5;
    
}

.rbctcsNameBanner{
    color: white;
    font-family: 'Roboto';
    font-weight: bold;
    padding: 10px;
}

.rbctcsWelcomeMessage{
    color: white;
    font-family: 'Roboto';
    font-weight: bold;
    padding: 10px;
    margin-left: 25%;

}


.logOutButton{
    float:right;
    right:10px;
}

.finalLogOutButton{
    background-color: red;
    color: white;
    font-size: x-large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    padding:10px;
    border-radius: 8px;
}