
.LRMWFiltersSearchDivTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
    font-size:12px;
}

.LRMWFiltersSearchDivTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.LRMWFiltersSearchDivTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.LRMWFiltersSearchDivTable thead td{
    padding:15px;
    
}

.LRMWFiltersSearchDivTable tbody td{
    padding:15px;
    border: 1px solid blue;
}