.tabPanelMainDiv{
    width: 96%;
    margin-top:2%;
    margin-left:2%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.tabPanelMainDiv fieldset{
    height:110vh;
    overflow-y: auto;
    border: 5px solid blue;
    border-radius: 8px;
    padding:10px;
    background-color: ghostwhite;
}

.tabPanelMainDiv fieldset legend{
    background-color: blue;
    border-radius: 8px;
    color:whitesmoke;
    padding: 10px;
}

.tabPanelMainDiv Table{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
    border-radius: 8px;
}

.tabPanelMainDiv Table thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.tabPanelMainDiv Table tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.tabPanelMainDiv Table thead td{
    padding:5px;
    text-align: center;
    border: 1px solid blue;
    
}

.tabPanelMainDiv Table tbody td{
    padding:5px;
    text-align: center;
    border: 1px solid blue;
}