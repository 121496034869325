.personalDetailsMainDiv .FormFieldDiv input[type=text], 
.personalDetailsMainDiv .FormFieldDiv input[type=date],
.personalDetailsMainDiv .FormFieldDiv input[type=email],
.personalDetailsMainDiv .FormFieldDiv input[type=number],
.personalDetailsMainDiv .FormFieldDiv textarea 
{
    padding: 12px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    font-size: medium;
    width: 300px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.personalDetailsMainDiv .FormFieldDiv input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus, input[type=email]:focus {
    background-color: cornsilk;
}

.actionsDiv{
    width: 100%;
    text-align: center;
    padding: 15px;
}

