.MemberPersonalDetailsViewByStaffOrAdminMainDiv{
    padding:10px;
    width:70%;
    margin:auto;
    font-size: small;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
}

.MemberPersonalDetailsViewByStaffOrAdminMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.MemberPersonalDetailsViewByStaffOrAdminMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.MemberPersonalDetailsViewByStaffOrAdminMainDiv table{
    
    padding:10px;
}

.MemberPersonalDetailsViewByStaffOrAdminMainDiv table td{
    padding:10px;
    border:1px solid blue;
    border-radius: 8px;
}

.MemberPersonalDetailsViewByStaffOrAdminMainDiv table> tbody > tr > td:first-child{
    background-color: blue;
    color:white;
}

.MemberPersonalDetailsViewByStaffOrAdminMainDiv img{
    width: 150px;
    height:150px;
}

.formatActions{
 padding:0;
 text-align: right;
}

.formatActions gridCol input[type=button]{
    margin:0px;
}