.MemberOfficialDetailsViewByStaffOrAdminMainDiv{
    padding:20px;
    width: 80%;
    margin-left:15%;
    font-size: small;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
}

.MemberOfficialDetailsViewByStaffOrAdminMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.MemberOfficialDetailsViewByStaffOrAdminMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.MemberOfficialDetailsViewByStaffOrAdminMainDiv table{
    
    padding:10px;
}

.MemberOfficialDetailsViewByStaffOrAdminMainDiv table td{
    padding:10px;
    border:1px solid blue;
    border-radius: 8px;
}

.MemberOfficialDetailsViewByStaffOrAdminMainDiv table> tbody > tr > td:first-child{
    background-color: blue;
    color:white;
}

.MemberOfficialDetailsViewByStaffOrAdminMainDiv img{
    width: 150px;
    height:150px;
}

.formatActions{
 padding:0;
 text-align: right;
}

.formatActions gridCol input[type=button]{
    margin:0px;
}

