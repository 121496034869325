body {
  background-image: url("./images/darjeelingImg-mainBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}