/* BankTrialBalance.css */

.trial-balance-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .trial-balance-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .date-picker-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .date-picker-label {
    font-size: 16px;
    margin-right: 10px;
  }
  
  .date-picker {
    padding: 10px;
  }
  
  .loading-message,
  .instruction-message {
    font-size: 16px;
    margin-top: 20px;
  }
  
  .trial-balance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .trial-balance-table th,
  .trial-balance-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .trial-balance-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  /* Add more styles as needed */
  