.BulletinBoard {
    width:100%;
    margin: auto;
    color:white;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 18px;
    border-radius: 8px;
   
}

.BulletinBoard fieldset{
    width: 100%;
    height: 90vh;
    overflow-y: auto;
    border-radius: 8px;
    background-color: ghostwhite;
    padding-top:20px;
    padding-bottom:20px;
    padding-left:10px;
    padding-right:10px;
    border: 5px solid blue;
    color:blue;
    text-align: left;
    
}

.BulletinBoard fieldset legend{
    padding:10px;
    background-color: blue;
    color:ghostwhite;
    border-radius: 8px;
    border: 5px solid blue;
}

.BulletinBoard table{
    width: 100%;
    font-size: 16px;
}

.BulletinBoard table thead{
   background-color: blue;
   color:white;
   padding:15px;
}

.BulletinBoard table thead td, .BulletinBoard table tbody td{
    
    padding:10px;
    border: 1px solid blue;
 }


 