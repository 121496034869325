.LoanFormDetailsMainDiv {
    width: 90%;
    height: 70vh;
    margin-left: 12%;
    margin-top: 1%;
    /* background-color: aqua; */
    text-align: center;
    padding: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;
    font-weight: bold;
   
}

.LoanFormDetailsMainDiv .FormFieldDiv input[type=text],
.LoanFormDetailsMainDiv .FormFieldDiv input[type=number],
.LoanFormDetailsMainDiv .FormFieldDiv input[type=month],
.LoanFormDetailsMainDiv .FormFieldDiv input[type=date]  
{
    padding: 12px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    font-size: medium;
    width: 350px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.LoanFormDetailsMainDiv .FormFieldDiv
input[type=text]:focus,
input[type=number]:focus, input[type=month]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.LoanFormDetailsMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.LoanFormDetailsMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
  }


.eligibilityDetailsDiv{
   padding: 5px;
   background-color: blue;
   color:whitesmoke;
   border-radius: 8px;
   text-align: justify;
}
.eligibilityDetailsDiv span{
  margin: 10px;
  padding:5px;
}

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.LoanFormDetailsMainDiv legend {
    text-align: justify;
    color: blue;
}