.suretyProviderDisplayDiv{
    width: 80%;
    margin: auto;
    padding: 10px;
    color: blue;
}

.suretyProviderDisplayTable{
    width: 100%;
    border-collapse: collapse;
    border-color: blue;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: x-large;
}