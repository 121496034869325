.submitPaymentRequestMainDiv .FormFieldDiv input,
.submitPaymentRequestMainDiv .FormFieldDiv input[type=text], 
.submitPaymentRequestMainDiv .FormFieldDiv input[type=number], 
.submitPaymentRequestMainDiv .FormFieldDiv input[type=date], 
.submitPaymentRequestMainDiv .FormFieldDiv input[type=email], 
.submitPaymentRequestMainDiv .FormFieldDiv input[type=password] {
    min-width:180px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 17px;
    font-weight:bold;
}

.submitPaymentRequestMainDiv .FormFieldDiv select{
    min-width:220px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 17px;
    font-weight:bold;
}

.submitPaymentRequestMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}
