.LRHWFiltersSearchDiv {
    width: 100%;
    color: blue;
    border-radius: 8px;
    text-align: center;
    overflow-x: auto; /* Set overflow-x to 'auto' or 'scroll' */
    /* white-space: nowrap; Prevent text from wrapping to the next line */
}


.LRHWFiltersSearchDiv .FormFieldDiv input,
.LRHWFiltersSearchDiv .FormFieldDiv input[type=text], 
.LRHWFiltersSearchDiv .FormFieldDiv select, 
.LRHWFiltersSearchDiv .FormFieldDiv input[type=number], 
.LRHWFiltersSearchDiv .FormFieldDiv input[type=date], 
.LRHWFiltersSearchDiv .FormFieldDiv input[type=email], 
.LRHWFiltersSearchDiv .FormFieldDiv input[type=password] {
    min-width:180px;
    padding:5px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 14px;
   
}

.LRHWFiltersSearchDiv .FormFieldDiv select:focus, input[type=text]:focus, input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}


.LRHWFiltersSearchDiv .FormActionDiv button,  input[type=reset], input[type=button],input[type=submit] {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: blue ;
    border: none;
    border-radius: 15px;
    box-shadow: 0 7px #999;
    margin: 5px;
    margin-top: 0px;
}

.LRHWFiltersSearchDiv .FormActionDiv button:hover, input[type=reset]:hover, input[type=button]:hover,input[type=submit]:hover {background-color: #3e8e41}

.LRHWFiltersSearchDiv .FormActionDiv button:active, input[type=reset]:active, input[type=button]:active,input[type=submit]:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

