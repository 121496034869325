.LDRDirections{
    padding: 2px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color:blue;
}

.fillChequeDetailsDiv{
     margin-top: 1%;
     padding:5px;
     background-color: lightblue;
     text-align: center;
}

.loanDisbursmentReportMainDiv .FormActionDiv{
    margin-top: 10px;
}