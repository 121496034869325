.ViewStaffDetailsMainDiv{
    width: 80%;
    padding:10px;
    margin:auto;
    z-index: 5;
    font-size: small;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
   
}

.ViewStaffDetailsMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:10px;
    border: 5px solid blue;
}

.ViewStaffDetailsMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.ViewStaffDetailsMainDiv .FormFieldDiv input, 
.ViewStaffDetailsMainDiv .FormFieldDiv input[type=text], 
.ViewStaffDetailsMainDiv .FormFieldDiv input[type=number], 
.ViewStaffDetailsMainDiv .FormFieldDiv input[type=date], 
.ViewStaffDetailsMainDiv .FormFieldDiv input[type=email], 
.ViewStaffDetailsMainDiv .FormFieldDiv input[type=password],
.ViewStaffDetailsMainDiv .FormFieldDiv select
 {
    padding: 12px;
    font-family: 'courier';
    font-weight: bold;
    font-size: small;
    width: 190px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.ViewStaffDetailsMainDiv .FormFieldDiv textarea{
    padding: 12px;
    font-family: 'courier';
    font-weight: bold;
    font-size: small;
    width: 190px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.ViewStaffDetailsMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}


.ViewStaffDetailsMainDiv table{
    
    padding:15px;
}

.ViewStaffDetailsMainDiv table td{
    padding:10px;
    border:1px solid blue;
    border-radius: 8px;
}

.ViewStaffDetailsMainDiv table> tbody > tr > td:first-child{
    background-color: blue;
    color:white;
}

.ViewStaffDetailsMainDiv img{
    width: 130px;
    height:130px;
}

