.LoanInstructionMainDiv{
    width: 80%;
    height: 60vh;
    padding:30px;
    margin-left: 12%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
    font-weight: bold;
    color: black;
}

.LoanInstructionMainDiv fieldset {
    width: inherit;
    border-radius: 8px;
    background-color: whitesmoke;
    border: 5px solid blue;
    overflow-y: auto;
    height: 60vh;
}

.LoanInstructionMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}
