.VerifyLoanApplicationsMainDiv{
    width: 90%;
    margin-top:1%;
    margin-left: 5%;
    font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: medium;
    font-weight: bold;
}

.VerifyLoanApplicationsMainDiv fieldset{
    border: 5px solid blue;
    border-radius: 8px;
    padding:10px;
    background-color: ghostwhite;
    height:100vh;
    overflow-y: auto;
}

.VerifyLoanApplicationsMainDiv fieldset legend{
    background-color: blue;
    border-radius: 8px;
    color:whitesmoke;
    padding: 10px;
}

.LoanApplicationStatusTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
    table-layout:inherit;

    /* font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: small;
    font-weight: bold; */
}

.LoanApplicationStatusTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
   
}

.LoanApplicationStatusTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.LoanApplicationStatusTable thead td{
    padding:7px;
    
}

.LoanApplicationStatusTable tbody td{
    padding:7px;
    border: 1px solid blue;
}