.NavigateBar {
    width: 100%;
    color: white;
    padding:2px;
    text-align: center;
    align-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
}

.NavigateMenu{
    margin-left:20%;
    margin-right:auto;
}

.NavigateMenu input[type=button]{
    padding: 7px;
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.bodyDiv{
   width: 100%;
   
}

.bodyContentDiv{
    width:100%;
    
    display: flex;
}

.imageFlasherDiv{
   width:fit-content;
}

.outletDiv{
    display: inline-block;
    width: 90%;
    margin-left: 25%;
    margin-top:8%;
    margin-bottom:8%;
    margin-right:10%;
   
    text-align: right;
}

.avtarProfileDiv{
    display: inline-block;
    width: fit-content;
    margin-right: 10px;
    height: 700px;
    
}

.shlokaFlashDiv{
    color:yellow;
    font-weight: bold;
    font-size: 14px;
}

marquee {
    height:"100%";
}