/* CustomDatePicker.css */

.custom-date-picker {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Add more styles as needed */
  