.dashBoardFooterDiv {
    background-color: blue;
    bottom: 0px;
    right: 0px;
    left: 0px;
    padding: 15px;
    z-index: 5;
    color: white;
    font-size: small;
    font-weight: bold;
    font-family: 'Roboto';
    text-align: center;
}