
.StaffCreationMainDiv {
    width: 95%;
    height: 60vh;
    margin-bottom: 5%;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 1%;
    /* background-color: aqua; */
    text-align: center;
    padding: 10px;
   
}

.StaffCreationMainDiv .FormFieldDiv input, 
.StaffCreationMainDiv .FormFieldDiv input[type=text], 
.StaffCreationMainDiv .FormFieldDiv input[type=number], 
.StaffCreationMainDiv .FormFieldDiv input[type=date], 
.StaffCreationMainDiv .FormFieldDiv input[type=email], 
.StaffCreationMainDiv .FormFieldDiv input[type=password],
.StaffCreationMainDiv .FormFieldDiv select
 {
    padding: 12px;
    font-family: 'courier';
    font-weight: bold;
    font-size: small;
    width: 190px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.StaffCreationMainDiv .FormFieldDiv textarea{
    padding: 12px;
    font-family: 'courier';
    font-weight: bold;
    font-size: small;
    width: 190px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.StaffCreationMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}

.StaffCreationMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding:30px;
    border: 5px solid blue;
}

.StaffCreationMainDiv fieldset legend{
    padding:10px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
  }

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.StaffCreationMainDiv legend {
    text-align: justify;
    color: blue;
}

.gridRow{
    display: flex;
}

.gridCol{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}


.StaffCreationMainDiv .errorDiv{
    color:red;
    font-weight: bold;
    text-align: right;
  }

.StaffCreationMainDiv .msgDiv{
    color: green;
    font-weight: bold;
    text-align: right;
    
}
