.memberExistingActiveLoansMainDiv{
    width: 96%;
    margin-top:1%;
    margin-left: auto;
    margin-right:auto;
    font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: small;
    font-weight: bold;
    
}

.memberExistingActiveLoansMainDiv fieldset{
    height:82vh;
    overflow-y: auto;
    border: 5px solid blue;
    border-radius: 8px;
    padding:10px;
    background-color: ghostwhite;
}

.memberExistingLoanPrePaymentDialogDiv fieldset{
    height:fit-content;
    overflow-y: auto;
    border: 5px solid blue;
    border-radius: 8px;
    padding:10px;
    background-color: ghostwhite;
    
}

.memberExistingActiveLoansMainDiv fieldset legend, .memberExistingLoanPrePaymentDialogDiv fieldset legend{
    background-color: blue;
    border-radius: 8px;
    color:whitesmoke;
    padding: 10px;
}



.memberExistingActiveLoansMainDiv .FormFieldDiv input,
.memberExistingActiveLoansMainDiv .FormFieldDiv input[type=text], 
.memberExistingActiveLoansMainDiv .FormFieldDiv select, 
.memberExistingActiveLoansMainDiv .FormFieldDiv input[type=number], 
.memberExistingActiveLoansMainDiv .FormFieldDiv input[type=date], 
.memberExistingActiveLoansMainDiv .FormFieldDiv input[type=email], 
.memberExistingActiveLoansMainDiv .FormFieldDiv input[type=password] {
    min-width:180px;
    padding:10px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
}

.memberExistingActiveLoansMainDiv .FormFieldDiv select:focus, input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus {
    background-color: cornsilk;
}



.memberExistingLoanPrePaymentDialogDiv .FormFieldDiv select,
.memberExistingLoanPrePaymentDialogDiv .FormFieldDiv input[type=text]
 {
    min-width:220px;
    padding:15px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
   
}

.memberExistingLoanPrePaymentDialogDiv .FormFieldDiv select:focus,
.memberExistingLoanPrePaymentDialogDiv .FormFieldDiv input[type=text]:focus{
    background-color: cornsilk;
}


.memberExistingLoanPrePaymentDialogDiv .FormFieldDiv input[type=file]
 {
    min-width:180px;
    padding:5px;
    border: 0px;
    border-radius: 8px;
    border: 1px solid blue;
    font-family: 'courier';
    font-size: 12px;
   
}

.memberExistingLoanPrePaymentDialogDiv .FormFieldDiv input[type=text]:focus{
    background-color: cornsilk;
}


.memberExistingLoanPrePaymentDialogDiv .FormActionDiv input[type=submit] {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: blue ;
    border: none;
    border-radius: 15px;
    box-shadow: 0 7px #999;
    margin-left: 70%;
  }

.memberExistingLoanPrePaymentDialogDiv .FormActionDiv input[type=submit]:hover {background-color: #3e8e41}

.memberExistingLoanPrePaymentDialogDiv .FormActionDiv input[type=submit]:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
