.VerifyMembershipApplicationsMainDiv{
    width: 98%;
    margin-top:1%;
    margin-left: 1%;
    font-family:Verdana, Geneva, Tahoma, sans-serif, Times, serif;
    font-size: small;
    font-weight: bold;
}

.VerifyMembershipApplicationsMainDiv fieldset{
    height: 85vh;
    border: 5px solid blue;
    border-radius: 8px;
    padding:5px;
    background-color: ghostwhite;
}

.VerifyMembershipApplicationsMainDiv fieldset legend{
    background-color: blue;
    border-radius: 8px;
    color:whitesmoke;
    padding: 5px;
}

.FilterApplicationsDiv{
    text-align: left;
    padding: 0;
}

.FilterApplicationsDiv select{
   padding:4px;
    font-weight: bold;
    font-size: medium;
    width: fit-content;
    border-radius: 8px;
    margin-bottom: 5px;
    border: 1px solid blueviolet;
    color: blue;
    
}

.VerifyMembershipApplicationsMainDiv .FormFieldDiv input[type=text], 
.VerifyMembershipApplicationsMainDiv .FormFieldDiv input[type=date],
.VerifyMembershipApplicationsMainDiv .FormFieldDiv input[type=email],
.VerifyMembershipApplicationsMainDiv .FormFieldDiv input[type=number],
.finalVerificationSubmitDialogDiv .FormFieldDiv input[type=text], 
.markForReviewDialogDiv .FormFieldDiv input[type=checkbox] 
{
    padding: 12px;
    font-weight: bold;
    font-size: medium;
    width: 300px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
    color: blue;
}

.VerifyMembershipApplicationsMainDiv .FormFieldDiv select:focus,input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus, input[type=email]:focus {
    background-color: cornsilk;
}

.membershipApplicationStatusTable{
    width:100%;
    border: 1px solid blue;
    border-collapse: collapse;
    
}

.membershipApplicationStatusTable thead{
    background-color: blue;
    color:white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.membershipApplicationStatusTable tbody{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.membershipApplicationStatusTable thead td{
    padding:5px;
    
}

.membershipApplicationStatusTable tbody td{
    padding:5px;
    border: 1px solid blue;
}

.markForReviewDialogDiv .FormActionDiv button,  .markForReviewDialogDiv .FormActionDiv input[type=reset], 
.markForReviewDialogDiv .FormActionDiv input[type=button],
.markForReviewDialogDiv .FormActionDiv input[type=submit] {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: blue ;
    border: none;
    border-radius: 15px;
    box-shadow: 0 7px #999;
    margin: 10px;
  }

.FormActionDiv button:hover, 
input[type=reset]:hover, input[type=button]:hover,input[type=submit]:hover {background-color: #3e8e41}

.FormActionDiv button:active, input[type=reset]:active, input[type=button]:active,input[type=submit]:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.finalVerificationSubmitDialogDiv p, .markForReviewDialogDiv p{
    font-size: 20px;
    font-weight: bold;
    text-align: justify;
    padding-left: 20px; padding-right: 10px;
    color: blue;
  }

  .dialogTitle{
    font-size: 16px;
    font-weight: bold;
    text-align: justify;
    padding: 8px;
    background-color: blue;
    color: white;
    
  }

  .markForReviewDialogDiv table{
    width: 90%;
    margin-left: 5%;
    font-size: 20px;
    font-weight: bold;
    text-align: justify;
    padding: 4px;
    background-color: lightgray;
    color: blue;
    
  }

  .justifyContent{
    text-align: justify;
  }

  .centerContent{
    text-align: center;
  }