
.personalDetailsMainDiv {
    width: 80%;
    height: 60vh;
    margin-left: 15%;
    margin-top: 2%;
    margin-bottom: 3%;
    /* background-color: aqua; */
    text-align: center;
    padding: 20px;
    font-size: medium;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
   
}

.personalDetailsMainDiv fieldset{
    width: fit-content;
    border-radius: 8px;
    background-color: ghostwhite;
    padding-left:50px;
    padding-right:50px;
    padding-top:10px;
    padding-bottom:10px;
    border: 5px solid blue;
}

.personalDetailsMainDiv fieldset legend{
    padding:5px;
    background-color: blue;
    color:whitesmoke;
    border-radius: 8px;
    border: 5px solid blue;
}

.personalDetailsMainDiv .FormFieldDiv input[type=text], 
.personalDetailsMainDiv .FormFieldDiv input[type=date],
.personalDetailsMainDiv .FormFieldDiv input[type=email],
.personalDetailsMainDiv .FormFieldDiv input[type=number],
.personalDetailsMainDiv .FormFieldDiv input,
.personalDetailsMainDiv .FormFieldDiv textarea
{
    padding: 10px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    font-size: medium;
    width: 300px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.personalDetailsMainDiv .FormFieldDiv input[type=text]:focus,
input[type=number]:focus, input[type=date]:focus, input[type=email]:focus {
    background-color: cornsilk;
}




.personalDetailsMainDiv fieldset {
    width: fit-content;
    border-radius: 8px;
   

}

.FormFieldDiv label {
    font-family: 'monospace';
    font-weight: bold;
    font-size: 15px;
    color: blue;
    margin-left: 10px;
}

.FormFieldDiv {
    text-align: justify;
    padding: 5px;
}

.personalDetailsMainDiv legend {
    text-align: justify;
    color: blue;
}

.gridRow{
    display: flex;
}

.gridCol{
    display: inline-block;
    margin: 10px;
}

