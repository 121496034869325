.LoanSuretyFormDetailsMainDiv{
  width: 85%;
  height: 90vh;
  margin-left: 7%;
  margin-top: 2%;
  padding: 30px;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: medium;
  font-weight: bold;
  
}

.LoanSuretyFormDetailsMainDiv fieldset{
  padding:10px;
  background-color: ghostwhite;
  border-radius: 8px;
  border: 5px solid blue;
  height:80vh;
  overflow-y: auto;
}

.LoanSuretyFormDetailsMainDiv fieldset legend{
  padding:10px;
  background-color: blue;
  color:whitesmoke;
  border-radius: 8px;
  border: 5px solid blue;
}

.LoanSuretyFormDetailsMainDiv .FormFieldDiv input,
.LoanSuretyFormDetailsMainDiv .FormFieldDiv input[type=text], 
.LoanSuretyFormDetailsMainDiv .FormFieldDiv select, 
.LoanSuretyFormDetailsMainDiv .FormFieldDiv input[type=number], 
.LoanSuretyFormDetailsMainDiv .FormFieldDiv input[type=date], 
.LoanSuretyFormDetailsMainDiv .FormFieldDiv input[type=email], 
.LoanSuretyFormDetailsMainDiv .FormFieldDiv input[type=password] {
    padding: 12px;
    font-family: 'courier';
    font-weight: bold;
    font-size: medium;
    width: 300px;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid blueviolet;
}

.LoanSuretyFormDetailsMainDiv .FormFieldDiv input::focus {
    background-color: cornsilk;
}

.LoanSuretyFormDetailsMainDiv input[type=button]{
  width: fit-content;
}

.loanSuretyDetailsTable {
  width:120%;
  border: 1px solid blue;
  border-collapse: collapse;
  padding: 20px;

}

.loanSuretyDetailsTable thead td{
  background-color: blue;
  color:white;
  border: 1px solid white;
  padding: 5px;
}

.loanSuretyDetailsTable td{
  padding:5px;
  text-align: justify;
}

.loanSuretyDetailsTableDiv{
  overflow-y: auto;
  width: 500px;
  margin-left: 2%;
}